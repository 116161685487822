import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { makeStyles } from "@material-ui/core";
import { Colors } from "../Constants/styles";

const useStyle = makeStyles({
  container: {
    position: "relative",
    width: "1440px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    overflow: "hidden",
    backgroundColor: Colors.white,
    zIndex: 0,
    boxShadow: " 0px 2px 20px rgba(0, 0, 0, 0.07)",
    ["@media (max-width:1440px)"]: {
      width: "100%",
    },
  },
});

const Layout = ({ children }) => {
  const classes = useStyle();
  return (
    <>
      <Navbar />
      <div className={classes.container}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
