import React, { useReducer, useState, useEffect } from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import { TextField, Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Colors } from "../Constants/styles";
import ContactInfo from "../components/ContactInfo";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Avatar } from "@material-ui/core";
import Andreas from "../assets/fonts/Images/AndreasContact.jpg";

const useStyle = makeStyles({
  infoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  icon: {
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 5,
    fontSize: "30px",
  },
  emailLink: {
    fontSize: "20px",
    textDecoration: "none",
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 5,
    color: Colors.white,
    "&:hover": {
      color: Colors.blue,
      cursor: "pointer",
    },
  },
  button: {
    width: 280,
    backgroundColor: Colors.blue,
    padding: "20px 25px 20px 25px",
    color: Colors.white,
    border: "none",
    margin: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    textDecoration: "none",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    width: "700px",
  },
  card: {
    width: "85%",
    alignSelf: "center",
    margin: "30px",
    backgroundColor: Colors.blue,
    color: Colors.white,
    padding: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "&> h1": {
      fontSize: "100px",
    },
    ["@media (max-width:1000px)"]: {
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  headerContainer: {
    textAlign: "left",
    margin: "20px",
    "& >h1": {
      fontSize: "40px",
    },
  },
  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 80,
  },
  contactImgContainer: {
    margin: "0px 20px 0px 20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width:1000px)"]: {
      width: "auto",
    },
  },
});
const Input = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Colors.blue,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: Colors.blue,
    },
    "& .Mui-error": {
      borderColor: "red",
    },
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: Colors.blue,
      },
    },
  },
})(TextField);

const initialState = {
  firstName: {
    value: "",
    valid: true,
  },
  lastName: {
    value: "",
    valid: true,
  },
  email: {
    value: "",
    valid: true,
  },
  phone: {
    value: "",
    valid: true,
  },
  message: {
    value: "",
    valid: true,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "firstName":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.key]: action.value,
        },
      };
    case "lastName":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.key]: action.value,
        },
      };
    case "email":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.key]: action.value,
        },
      };
    case "phone":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.key]: action.value,
        },
      };
    case "message":
      return {
        ...state,
        [action.type]: {
          ...state[action.type],
          [action.key]: action.value,
        },
      };
    default:
      return;
  }
};

const Contact = () => {
  const classes = useStyle();
  const [state, dispatchReducer] = useReducer(reducer, initialState);
  const [formValid, setFormValid] = useState(false);
  let valid;

  const validateText = (text, key, type) => {
    valid = true;
    if (type === "number") {
      if (text.trim() === "") {
        valid = false;
      }
      if (text.length === 0) {
        valid = false;
      }
    } else {
      if (text.trim() === "") {
        valid = false;
      }
      if (text.length < 3) {
        valid = false;
      }
    }
    dispatchReducer({ type: key, value: valid, key: "valid" });
  };
  const formValidation = () => {
    const a = Object.keys(state);
    let valid = true;
    a.forEach((item) => {
      const b = state[item];
      if (b.valid === false || b.value === "") {
        valid = false;
      }
    });

    return valid;
  };
  useEffect(() => {
    setFormValid(formValidation());
  }, [state]);

  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Contact page" />
      <Grid style={{ marginTop: 100 }} container spacing={0}>
        <Grid item lg={4} xs={12}>
          <div className={classes.card}>
            <div className={classes.contactImgContainer}>
              <h1 style={{ fontSize: "44px" }}>Kontakta oss</h1>

              <Avatar
                src={Andreas}
                alt="Andreas Niklasson"
                style={{
                  width: "250px",
                  height: "250px",
                  alignSelf: "center",
                  marginBottom: 40,
                }}
              />
            </div>
            <div style={{ margin: "auto" }}>
              <div style={{ margin: "auto" }}>
                <ContactInfo color={Colors.white} activeColor={Colors.blue} />
                <div className={classes.infoItem}>
                  <LinkedInIcon className={classes.icon} />
                  <a
                    className={classes.emailLink}
                    href="https://www.linkedin.com/in/andreas-niklasson-43b072a0/"
                  >
                    Andreas Niklasson
                  </a>
                </div>
                <div className={classes.infoItem}>
                  <LinkedInIcon className={classes.icon} />
                  <a
                    className={classes.emailLink}
                    href="https://www.linkedin.com/company/nordre-engineering-ab/about/"
                  >
                    Nordre Engineering AB
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={8} xs={12}>
          <div className={classes.form}>
            <form
              className={classes.formContainer}
              name="contact"
              method="post"
            >
              <input type="hidden" name="form-name" value="contact" />

              <div className={classes.headerContainer}>
                <h1>Skicka oss ett meddelande</h1>
                <p>
                  Tveka inte att kontakta oss för att se hur vi kan hjälpa er
                  med ert projekt och era behov
                </p>
              </div>
              <div>
                <Input
                  type="text"
                  style={{ width: "300px", margin: 20 }}
                  label="Förnamn"
                  placeholder="First name"
                  name="firstName"
                  value={state.firstName.value}
                  onChange={(event) =>
                    dispatchReducer({
                      type: "firstName",
                      value: event.target.value,
                      key: "value",
                    })
                  }
                  onBlur={(event) =>
                    validateText(
                      state["firstName"].value,
                      event.target.name,
                      event.target.type
                    )
                  }
                  error={!state["firstName"].valid}
                  required
                />
                <Input
                  type="text"
                  style={{ width: "300px", margin: 20 }}
                  label="Efternamn"
                  placeholder="Last name"
                  name="lastName"
                  value={state.lastName.value}
                  onChange={(event) =>
                    dispatchReducer({
                      type: "lastName",
                      value: event.target.value,
                      key: "value",
                    })
                  }
                  onBlur={(event) =>
                    validateText(
                      state["lastName"].value,
                      event.target.name,
                      event.target.type
                    )
                  }
                  error={!state["lastName"].valid}
                  required
                />
              </div>
              <div>
                <Input
                  type="text"
                  style={{ width: "300px", margin: 20 }}
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={state.email.value}
                  onChange={(event) =>
                    dispatchReducer({
                      type: "email",
                      value: event.target.value,
                      key: "value",
                    })
                  }
                  onBlur={(event) =>
                    validateText(
                      state["email"].value,
                      event.target.name,
                      event.target.type
                    )
                  }
                  error={!state["email"].valid}
                  required
                />
                <Input
                  style={{ width: "300px", margin: 20 }}
                  label="Telefon"
                  placeholder="Phone"
                  type="number"
                  name="phone"
                  value={state.phone.value}
                  onChange={(event) =>
                    dispatchReducer({
                      type: "phone",
                      value: event.target.value,
                      key: "value",
                    })
                  }
                  onBlur={(event) =>
                    validateText(
                      state["phone"].value,
                      event.target.name,
                      event.target.type
                    )
                  }
                  error={!state["phone"].valid}
                  required
                />
              </div>
              <div>
                <Input
                  type="text"
                  style={{ width: "630px", margin: 20 }}
                  label="Meddelande"
                  multiline
                  rows={4}
                  name="message"
                  value={state.message.value}
                  onChange={(event) =>
                    dispatchReducer({
                      type: "message",
                      value: event.target.value,
                      key: "value",
                    })
                  }
                  onBlur={(event) =>
                    validateText(
                      state["message"].value,
                      event.target.name,
                      event.target.type
                    )
                  }
                  error={!state["message"].valid}
                  required
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  disabled={!formValid}
                  name="button"
                  type="submit"
                  className={classes.button}
                >
                  Skicka Meddelande
                </button>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Contact;
