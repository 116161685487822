import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";

const Jobs = () => {
  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Job page" />
    </Layout>
  );
};

export default Jobs;
