export const Colors = {
  blue: "#4196E5",
  white: "#fff",
  black: "#000000",
  dark_white: "#F1F1F1",
};
export const fonts = {
  fontFamily: "Metropolis",
};
export const weight = {};
