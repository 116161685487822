import React, { useState } from "react";
import Logga from "../assets/fonts/Images/nordre.jpg";
import { makeStyles } from "@material-ui/styles";
import { NavLink, Link } from "react-router-dom";
import { Colors } from "../Constants/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CustomDrawer from "../components/CustomDrawer";
import { navLinks } from "../Constants/data";
import ContactInfo from "./ContactInfo";

const Navbar = () => {
  const [drawerState, setDrawerState] = useState(false);

  const toggleDrawer = () => {
    setDrawerState((prev) => !prev);
  };

  const classes = useStyle();

  return (
    <div className={classes.navBar}>
      <div className={classes.container}>
        <CustomDrawer toggleDrawer={toggleDrawer} drawerState={drawerState} />
        <div className={classes.imgContainer}>
          <Link className={classes.img} exact to="/">
            <img src={Logga} alt="Logga" style={{ width: "200px" }} />
          </Link>
          <div className={classes.infoContainer}>
            <ContactInfo color={Colors.black} activeColor={Colors.blue} />
          </div>
        </div>
        <div className={classes.menuIconContainer}>
          <MenuIcon onClick={toggleDrawer} className={classes.menuIcon} />
        </div>
        <div className={classes.linkContainer}>
          {navLinks.map((item) => {
            return (
              <NavLink
                key={item.id}
                className={classes.tabs}
                activeClassName={classes.activeTab}
                to={item.link}
                exact={item.exact}
              >
                <p style={{ width: 80 }}>{item.label}</p>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const useStyle = makeStyles({
  container: {
    position: "relative",
    width: "1440px",
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto",
    overflow: "hidden",
    backgroundColor: Colors.white,
    zIndex: -10,
    ["@media (max-width:1440px)"]: {
      width: "100%",
    },
  },
  menuIcon: {
    fontSize: 80,
  },
  menuIconContainer: {
    display: "none",
    ["@media (max-width:1150px)"]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "8%",
    },
  },
  linkContainer: {
    marginRight: "40px",
    marginLeft: "10px",
    display: "flex",
    flexDirection: "row",
    ["@media (max-width:1150px)"]: {
      display: "none",
    },
  },
  infoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "auto",
  },
  tabs: {
    textDecoration: "none",
    color: Colors.black,
    backgroundColor: Colors.white,
    padding: "0px 10px 0px 10px",
  },
  activeTab: {
    backgroundColor: Colors.blue,
    color: Colors.white,
    textDecoration: "none",
    padding: "0px 10px 0px 10px",
  },
  img: {
    width: "60%",
    marginLeft: 10,
    marginRight: 50,
    margin: "40px 30px 40px 10px",
  },
  navBar: {
    position: "fixed",
    width: "100%",
    height: 100,
    borderBottom: "5px solid #4196E5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 110,
    backgroundColor: Colors.white,
  },
  imgContainer: {
    marginLeft: 10,
    flex: 0.4,
    display: "flex",
    alignItems: "center",
  },
});
export default Navbar;
