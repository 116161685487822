import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import image1 from "../assets/fonts/Images/image4.png";
import image2 from "../assets/fonts/Images/image5.png";
import image3 from "../assets/fonts/Images/pencil.jpg";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles({
  sliderimg: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
});

const Caurosel = () => {
  const classes = useStyle();
  const handleDragStart = (e) => e.preventDefault();

  const image = [
    <img
      src={image3}
      className={classes.sliderimg}
      onDragStart={handleDragStart}
      alt=""
    />,
  ];

  return (
    <AliceCarousel
      duration={400}
      autoPlay={true}
      startIndex={1}
      fadeOutAnimation={true}
      mouseDragEnabled={true}
      playButtonEnabled={true}
      autoPlayInterval={2000}
      autoPlayDirection="rtl"
      autoPlayActionDisabled={true}
      mouseTracking
      items={image}
    />
  );
};

export default Caurosel;
