export const navLinks = [
  {
    id: 0,
    link: "/",
    label: "Hem",
    exact: true,
  },
  /* {
    id: 1,
    link: "/jobs",
    label: "career",
    exact: false,
  },*/
  {
    id: 5,
    link: "/project",
    label: "Projekt",
    exact: false,
  },
  {
    id: 6,
    link: "/services",
    label: "Tjänster",
    exact: false,
  },
  {
    id: 2,
    link: "/partner",
    label: "Partners",
    exact: false,
  },
  {
    id: 3,
    link: "/aboutUs",
    label: "Om Nordre",
    exact: false,
  },
  {
    id: 4,
    link: "/contact",
    label: "Kontakt",
    exact: false,
  },
];
