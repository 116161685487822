import { string } from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const CustomHelmet = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Lorem ipsum" />
      <meta
        name="keywords"
        content="manufacturing,purchase,retail,product business,Engineering, Industrial Design, Engineering, Kungälv, Westcost,
        Production, Nordre Engineering AB, Nordre"
      />
      <link rel="canonical" href="https://yourapp.com" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Nordre Engineering AB" />
      <meta property="og:description" content="Lorem Ipsum" />
      <meta property="og:url" content="https://yourapp.com" />
      <meta property="og:site_name" content="Your App" />
      <meta property="og:image" content="yourimage.jpg" />
      <meta property="og:image:secure_url" content="yourimage.jpg" />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content="Lorem ipsum" />
      <meta name="twitter:title" content="Nordre Engineering AB" />
      <meta name="twitter:image" content="yourimage.png" />
      <meta charset="utf-8" />
    </Helmet>
  );
};
CustomHelmet.propTypes = {
  title: string.isRequired,
};
export default CustomHelmet;
