import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import ServiceCard from "../components/ServiceCard";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/Loading";
import Pic from "../assets/fonts/Images/image4.png";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles({
  container: {
    display: "flex",
    margin: 20,
    justifyContent: "space-around",
  },
  paragraph: {
    fontSize: 24,
    fontFamily: "RobotoRegular",
    width: "85%",
    margin: "auto",
    textAlign: "left",
    padding: "40px 0px 40px 0px",
  },
});

const servicesQuery = gql`
  query {
    jobsNordres {
      title
      description
      image {
        url
      }
    }
  }
`;
const Services = () => {
  const classes = useStyle();
  const { loading, error, data } = useQuery(servicesQuery);
  if (loading) return <Loading />;

  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Services page" />
      <div style={{ marginTop: 100, marginBottom: 50, width: "100%" }}>
        {/*<img
          src={Pic}
          style={{ width: "100%", height: 300, objectFit: "cover" }}
        />*/}

        {data.jobsNordres.length === 0 && (
          <h1 style={{ fontFamily: "Roboto" }}>
            Det finns inga projekt tillgängliga för tillfället
          </h1>
        )}
        {data &&
          data.jobsNordres.map((item, index) => (
            <ServiceCard
              state={index % 2 === 0}
              title={item.title}
              description={item.description}
              key={index}
              img={item.image ? item.image.url : null}
            />
          ))}
        {/*<p className={classes.paragraph}>
          Nordre Engineering AB tillhandahåller produktionsklara lösningar för
          era kundbehov. Med ett brett kontaktnät hjälper vi våra partners
          leverera kvalitetssäkrade produkter.
          </p>*/}
        {/*<div style={{ width: "90%", margin: "auto" }}>
          <img
            src={Pic}
            style={{ width: "100%", height: 300, objectFit: "cover" }}
          />
        </div>*/}
      </div>
    </Layout>
  );
};

export default Services;
