import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Colors } from "../Constants/styles";
import { gql, useQuery } from "@apollo/client";
import MainCard from "../components/MainCard";
import DefaultButton from "../components/DefaultButton";
import Loading from "../components/Loading";
import Andreas from "../assets/fonts/Images/AndreasStor.jpg";
const useStyle = makeStyles({
  mainPic: {
    margin: 0,
    position: "absolute",
    right: 0,
    height: "500px",
    width: 1000,
    marginLeft: 0,
    overflow: "hidden",
    zIndex: "-10",
    objectFit: "cover",
    ["@media (max-width:1200px)"]: {
      position: "relative",
      width: "100vw",
      height: "auto",
      zIndex: 0,
    },
  },
  title: {
    marginTop: 70,
    textAlign: "left",
    margin: 50,
  },
  text: {
    textAlign: "left",
    margin: 50,
    fontSize: "16px",
    lineHeight: 1.5,
    fontFamily: "RobotoRegular",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width:1200px)": {
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

const aboutQuery = gql`
  query {
    nordres {
      title
      header
      headDescription
      img {
        url
      }
    }
  }
`;

const AboutUs = () => {
  const classes = useStyle();
  const { loading, error, data } = useQuery(aboutQuery);
  if (loading) return <Loading />;

  return (
    <Layout>
      <CustomHelmet title={data.nordres[0].header} />
      <Grid style={{ marginTop: 100 }} container>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.topContainer}>
            <img
              src={data?.nordres[0].img.url}
              className={classes.mainPic}
              alt=""
            />
            <MainCard
              header={data?.nordres[0].header}
              description={data?.nordres[0].headDescription}
              path="/services"
              label="Tjänster"
            />
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <h1 className={classes.title}>Vi finns för industrin </h1>
          <p className={classes.text}>
            Nordre Engineering AB är en Ingenjörsbyrå i första generationen.
            Företaget startades och drivs av Andreas Niklasson som tillsammans
            med samarbetspartners och kunder levererar lösningar på tekniska
            problem för tillverkande industrier och produktbolag. Nordre
            Engineering AB har med partners möjlighet att erbjuda
            produktutveckling från idé till produktion i flertalet branscher;
            där ibland marinteknik, tillverkningsteknik och agroteknik.
          </p>
          <div style={{ marginLeft: 50 }}>
            <DefaultButton link="/services" label="Se våra tjänster" />
          </div>
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          style={{
            display: "flex",
            justififyContent: "center",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <img
            src={Andreas}
            alt="Andreas Niklasson"
            style={{ width: "95%", marginTop: 40 }}
          />
          <p style={{ marginBottom: 50, fontSize: 12 }}>
            Andreas Niklasson - Business owner, Nordre AB
          </p>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: 50 }} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AboutUs;

const text = (
  <>
    <h1>Corporate Policy</h1>
    <p>
      Nordre Engineering AB shall be a comprehensive and leading supplier of
      modern products around electronics and software. Our customers must feel
      secure that we have the competence, experience and equipment required to
      be at the forefront of the industry.
      <br />
      <br /> We must always have an open and honest dialogue with customers and
      suppliers and create and maintain long-term and good relationships. <br />
      <br />
      We always prioritize the integrity of our customers so that they
      experience us as a stable and professional partner. We must always meet or
      exceed the legal and other binding requirements imposed on the business,
      energy efficiency and contribute to protecting the environment and
      reducing the environmental impact.
      <br />
      <br /> By being innovative and constantly evaluating and applying new
      technology, we will at the same time give our customers a basis for
      putting competitive and environmentally friendly products on the market.
      Our workplace must be characterized by a strong team spirit where we
      always work together and relieve each other to achieve our goals and
      deliveries.
      <br />
      <br /> Every individual must have the freedom to develop and feel
      appreciation and belonging, which gives us a long-term pleasant and
      stress-free work environment. Nordre Engineering AB will be perceived by
      other parties in the industry as the leading supplier in product
      development and manufacturing, where we have a prominent position with
      modern machines, modern equipment and motivated resources.
    </p>
  </>
);
