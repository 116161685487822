import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Colors } from "../Constants/styles";
import DefaultButton from "./DefaultButton";

const MainCard = ({ description, header, path, label }) => {
  const classes = useStyle();
  return (
    <div className={classes.card}>
      <h1 className={classes.headerTexts}>{header}</h1>
      <p className={classes.paragraph}>{description}</p>

      <DefaultButton link={path} label={label} />
    </div>
  );
};

const useStyle = makeStyles({
  headerTexts: {
    margin: 3,
    fontSize: 50,
    fontWeight: "bold",
    lineHeight: "125%",
    textAlign: "left",
    padding: 10,
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    "-moz-white-space": "pre-wrap",
    width: "100%",
  },
  card: {
    position: "relative",
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: 500,
    height: 360,
    padding: 40,
    margin: 30,
    boxShadow: " 0px 2px 20px rgba(0, 0, 0, 0.07)",
    ["@media (max-width:1200px)"]: {
      width: "90vw",
      height: "auto",
      zIndex: 100,
      margin: 20,
    },
  },
  button: {
    backgroundColor: Colors.blue,
    padding: "20px 25px 20px 25px",
    color: Colors.white,
    border: "none",
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    textDecoration: "none",
  },
  paragraph: {
    textAlign: "left",
    marginLeft: 8,
    width: "100%",
    fontSize: 20,
    fontFamily: "RobotoRegular",
  },
});

export default MainCard;
