import { Grid } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Link } from "react-router-dom";
import Logga from "../assets/fonts/Images/nordre.jpg";
import { Colors } from "../Constants/styles";
import ContactInfo from "./ContactInfo";
import DefaultButton from "./DefaultButton";

const Footer = () => {
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid
          item
          lg={4}
          xs={12}
          style={{
            display: "flex",
            marginBottom: 40,
          }}
        >
          <Link className={classes.logga} exact to="/">
            <img src={Logga} alt="Nordre AB" style={{ width: "300px" }} />
          </Link>
        </Grid>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 40,
          }}
          item
          lg={4}
          xs={12}
        >
          <div className={classes.infoContainer}>
            <ContactInfo color={Colors.black} activeColor={Colors.blue} />

            <div className={classes.infoItem}>
              <LinkedInIcon className={classes.icon} />
              <a
                className={classes.emailLink}
                href="https://www.linkedin.com/in/andreas-niklasson-43b072a0/"
              >
                Andreas Niklasson
              </a>
            </div>
            <div className={classes.infoItem}>
              <LinkedInIcon className={classes.icon} />
              <a
                className={classes.emailLink}
                href="https://www.linkedin.com/company/nordre-engineering-ab/about/"
              >
                Nordre Engineering AB
              </a>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className={classes.buttonContainer}>
            <DefaultButton link="/contact" label="Kontakt" />

            {/*<DefaultButton link="/" label="Privacy" />*/}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const useStyle = makeStyles({
  tab: {
    margin: 10,
    width: 100,
    backgroundColor: Colors.blue,
    color: Colors.white,
    padding: "20px 17px 20px 17px",
    textDecoration: "none",
  },
  logga: { margin: "auto" },
  container: {
    backgroundColor: Colors.white,
    color: Colors.blue,
    width: "100%",
    margin: 0,
    paddingTop: 50,
    paddingBottom: 50,
  },
  linkContainer: {
    marginRight: "2%",
  },
  infoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "auto",
  },
  icon: {
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 5,
  },
  emailLink: {
    fontSize: "14px",
    textDecoration: "none",
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 5,
    color: Colors.black,
    "&:hover": {
      color: Colors.blue,
      cursor: "pointer",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
  },
});
export default Footer;
