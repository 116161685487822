import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/Loading";
import ServiceCard from "../components/ServiceCard";
import Pic from "../assets/fonts/Images/image4.png";

const projectQuery = gql`
  query {
    projectsNordres {
      title
      description
      image {
        url
      }
    }
  }
`;

const Project = () => {
  const { loading, error, data } = useQuery(projectQuery);
  if (loading) return <Loading />;

  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Project page" />
      <div
        style={{
          marginTop: 100,
          marginBottom: 50,
          width: "100%",
        }}
      >
        {/*<img
          src={Pic}
          style={{ width: "100%", height: 300, objectFit: "cover" }}
        />*/}

        {data.projectsNordres.length === 0 && (
          <h1 style={{ fontFamily: "Roboto" }}>
            Det finns inga projekt tillgängliga för tillfället
          </h1>
        )}
        {data &&
          data.projectsNordres.map((item, index) => (
            <ServiceCard
              state={index % 2 === 0}
              title={item.title}
              description={item.description}
              key={index}
              img={item.image.url}
            />
          ))}
        {/*<div style={{ width: "90%", margin: "auto" }}>
          <img
            src={Pic}
            style={{ width: "100%", height: 300, objectFit: "cover" }}
          />
          </div>*/}
      </div>
    </Layout>
  );
};

export default Project;
