import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { ApolloProvider } from "@apollo/client";
import ApolloClient from "apollo-boost";
const URL = process.env.REACT_APP_URL;

const client = new ApolloClient({
  uri: URL,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <I18nextProvider i18next={i18next}>
        <App />
      </I18nextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
