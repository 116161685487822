import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Colors } from "../Constants/styles";
import { gql, useQuery } from "@apollo/client";
import Caorsel from "../components/Caurosel";
import DefaultButton from "../components/DefaultButton";
import MainCard from "../components/MainCard";
import Loading from "../components/Loading";

const useStyle = makeStyles({
  blueText: {
    color: Colors.blue,
    textAlign: "start",
  },
  cardTwo: {
    position: "relative",
    backgroundColor: Colors.dark_white,
    height: "auto",
    fontSize: "20px",
    padding: 40,
    paddingRight: 50,
    width: 500,
    margin: 30,
  },
  headerTexts: {
    fontSize: 50,
    fontWeight: "bold",
    textAlign: "left",
    width: "100%",
    marginLeft: 15,
  },
  paragraph: {
    textAlign: "left",
    marginLeft: 15,
    fontSize: 24,
    fontFamily: "RobotoRegular",
  },
  paragraphTwo: {
    fontSize: 24,
    fontFamily: "RobotoRegular",
    marginLeft: 16,
    textAlign: "start",
    ["@media (max-width:1000px)"]: {
      fontSize: 10,
    },
  },
  mainPic: {
    margin: 0,
    position: "absolute",
    right: 0,
    height: "500px",
    width: 1000,
    marginLeft: 0,
    overflow: "hidden",
    zIndex: "-10",
    objectFit: "cover",
    ["@media (max-width:1200px)"]: {
      position: "relative",
      width: "100vw",
      height: "auto",
      zIndex: 0,
    },
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    ["@media (max-width:1200px)"]: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
});

const homeQuery = gql`
  query {
    nordres {
      title
      header
      headDescription
      img {
        url
      }
    }
  }
`;
const Home = () => {
  const classes = useStyle();

  const { loading, error, data } = useQuery(homeQuery);
  if (loading) return <Loading />;

  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Home page" />
      <Grid style={{ marginTop: 100 }} container spacing={1}>
        <Grid item xs={12}>
          <Grid item xs={12} className={classes.topContainer}>
            <img
              src={data?.nordres[0].img.url}
              className={classes.mainPic}
              alt=""
            />

            <MainCard
              header={data?.nordres[0].title}
              description={data?.nordres[0].headDescription}
              path="/project"
              label="Projekt"
            />
          </Grid>
          <Grid item xs={12} className={classes.expand}>
            <div style={{ margin: 40 }}>
              {/*<h1 className={classes.headerTexts}>Tankegods från södra Bohuslän</h1>*/}
              <p className={classes.paragraphTwo}>
                Leveranser från Nordre Engineering bygger på klassiskt
                ingenjörsarbete. I grund och botten handlar det om att optimera
                produkter och processer för att minimera kostnader och maximera
                kundnytta.
              </p>
            </div>
          </Grid>
        </Grid>

        <Grid
          style={{ backgroundColor: Colors.dark_white }}
          item
          md={6}
          xs={12}
        >
          <div className={classes.cardTwo}>
            <p className={classes.blueText}>Från behov till produktion</p>
            {/*<h1 className={classes.headerTexts}>
              The Difference is in the details
              </h1>*/}
            <p className={classes.paragraph}>
              I 5 länder tillverkar 10 fabriker produkter konstruerade av
              Nordre.
            </p>
            <div style={{ marginLeft: 15 }}>
              <DefaultButton link="/services" label="Se våra tjänster" />
            </div>
          </div>
        </Grid>
        <Grid
          style={{
            backgroundColor: Colors.dark_white,
            padding: "20px 40px 20px 40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
          }}
          item
          md={6}
          xs={12}
        >
          <Caorsel />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
