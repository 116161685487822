import React from "react";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { navLinks } from "../Constants/data";
import { makeStyles } from "@material-ui/styles";
import { Colors } from "../Constants/styles";
import { NavLink } from "react-router-dom";

const CustomDrawer = ({ drawerState, toggleDrawer }) => {
  const classes = useStyle();
  return (
    <SwipeableDrawer
      anchor="left"
      open={drawerState}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <List style={{ width: "300px" }}>
        {navLinks.map((item) => {
          return (
            <ListItem key={item.id} classes={{ root: classes.tabs }} button>
              <NavLink
                activeClassName={classes.activeTab}
                className={classes.tabs}
                exact
                to={item.link}
              >
                <ListItemText primary={<h1>{item.label}</h1>} />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    </SwipeableDrawer>
  );
};
const useStyle = makeStyles({
  tabs: {
    margin: 0,
    width: "100%",
    padding: 10,
    textDecoration: "none",
    color: Colors.black,
    fontSize: 20,
  },
  activeTab: {
    margin: 0,
    backgroundColor: Colors.blue,
    color: Colors.white,
    textDecoration: "none",
  },
});
export default CustomDrawer;
