import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyle = makeStyles({
  container: {
    display: "flex",
    margin: 20,
    justifyContent: "space-around",
    alignItems: "center",
    ["@media (max-width:1200px)"]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  paragraph: {
    fontSize: 24,
    fontFamily: "RobotoRegular",
    textAlign: "left",
    ["@media (max-width:1200px)"]: {
      textAlign: "center",
    },
  },
  header: {
    textAlign: "left",
    ["@media (max-width:1200px)"]: {
      textAlign: "center",
    },
  },
  column: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "left",
    flexDirection: "column",
    ["@media (max-width:1200px)"]: {
      width: "90%",
      alignItems: "center",
    },
  },
  img: {
    width: "50%",
    height: "50%",
    objectFit: "cover",
    ["@media (max-width:1200px)"]: {
      width: "90%",
    },
  },
});

const ServiceCard = ({ state, img, title, description }) => {
  const classes = useStyle();
  const { innerWidth: width } = window;

  return (
    <>
      {width > 1200 ? (
        state ? (
          <div className={classes.container}>
            <div className={classes.column}>
              <h1 className={classes.header}>{title}</h1>
              <p className={classes.paragraph}>{description}</p>
            </div>
            <img src={img} alt={title} className={classes.img} />
          </div>
        ) : (
          <div className={classes.container}>
            <img src={img} alt={title} className={classes.img} />
            <div className={classes.column}>
              <h1 className={classes.header}>{title}</h1>
              <p className={classes.paragraph}>{description}</p>
            </div>
          </div>
        )
      ) : (
        <div className={classes.container}>
          <img src={img} alt={title} className={classes.img} />
          <div className={classes.column}>
            <h1 className={classes.header}>{title}</h1>
            <p className={classes.paragraph}>{description}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceCard;
