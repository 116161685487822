import React from "react";
import { makeStyles } from "@material-ui/styles";
import LocalPhoneOutlinedIcon from "@material-ui/icons/LocalPhoneOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";

const useStyle = makeStyles((props) => ({
  infoItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  icon: {
    fontSize: "30px",
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 5,
  },
  emailLink: {
    fontSize: "20px",
    textDecoration: "none",
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    margin: 10,
    color: (props) => props.color,
    "&:hover": {
      color: (props) => props.activeColor,
      cursor: "pointer",
    },
  },
}));

const ContactInfo = (props) => {
  const classes = useStyle(props);
  return (
    <>
      <div className={classes.infoItem}>
        <LocalPhoneOutlinedIcon className={classes.icon} />
        <a className={classes.emailLink} href="tel:0763181825">
          +46763181825
        </a>
      </div>
      <div className={classes.infoItem}>
        <MailOutlinedIcon className={classes.icon} />
        <a className={classes.emailLink} href="mailto: info@nordre.se">
          info@nordre.se
        </a>
      </div>
    </>
  );
};

export default ContactInfo;
