import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Jobs from "./pages/Jobs";
import Services from "./pages/Services";
import Project from "./pages/Project";
import CookieConsent from './components/CookieConsent';
import Partners from './pages/Partners';

const App = () => {

  return (
    <BrowserRouter>
        <div className="App">
        <CookieConsent />
          <Switch>
            <Route path="/aboutUs">
              <AboutUs />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/jobs">
              <Jobs />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/project">
              <Project />
            </Route>
            <Route path="/partner">
              <Partners />
            </Route>
            <Route exact={true} path="/">
              <Home />
            </Route>
          </Switch>
        </div>
    </BrowserRouter>
  );
};

export default App;
