import React from "react";
import { makeStyles } from "@material-ui/styles";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Colors } from "../Constants/styles";
import {  useHistory } from "react-router-dom";

const useStyle = makeStyles({
  button: {
    position: "relative",
    width: 200,
    backgroundColor: Colors.blue,
    padding: "20px 25px 20px 25px",
    color: Colors.white,
    border: "none",
    marginTop: 20,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "20px",
    textDecoration: "none",
    transition: "box-shadow 0.5s ease-in-out",
    "&>div>p": {
      position: "absolute",
      left: 180,
      top: -5,
      transition: "left  0.5s ease-in-out",
    },
    "&:hover": {
      boxShadow:
        " 0px 8px 16px 0px rgba(0,0,0,0.2), 0px 6px 20px 0px rgba(0,0,0,0.19)",
      "&>div>p": {
        left: 200,
      },
    },
  },
});
const DefaultButton = ({ link, label }) => {
  const classes = useStyle();
  const h = useHistory();
  return (
    <div className={classes.button} onClick={() => h.push(link)}>
      {label}
      <div>
        <p className={classes.arrow}>
          <ArrowRightAltIcon fontSize="large" />
        </p>
      </div>
    </div>
  );
};

export default DefaultButton;
