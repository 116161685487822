import React from "react";
import Layout from "../components/Layout";
import CustomHelmet from "../components/Helmet";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/Loading";
import ServiceCard from "../components/ServiceCard";
import Pic from "../assets/fonts/Images/image4.png";

const partners =[

]

const Partners = () => {

  return (
    <Layout>
      <CustomHelmet title="Nordre Engineering AB Project page" />
      <div
        style={{
          marginTop: 100,
          marginBottom: 50,
          width: "100%",
        }}
      >
        {partners.length === 0 && (
          <h1 style={{ fontFamily: "Roboto" }}>
            Partners kommer innom kort
          </h1>
        )}
        {partners &&
          partners.map((item, index) => (
            <ServiceCard
              state={index % 2 === 0}
              title={item.title}
              description={item.description}
              key={index}
              img={item.image.url}
            />
          ))}
      </div>
    </Layout>
  );
};

export default Partners;
